import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_alltomtraning/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "virtufit" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				size
				length
				weight
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "virtufit-träningsutrustning"
    }}>{`VirtuFit Träningsutrustning`}</h1>
    <p>{`VirtuFit är ett ledande varumärke inom träningsutrustning, känt för att leverera högkvalitativa produkter som passar alla nivåer av träning - från nybörjare till proffs. Våra produkter är designade för att erbjuda en mångsidig och effektiv träningsupplevelse som hjälper dig att nå dina fitnessmål med stil och komfort. Oavsett om du tränar hemma eller på gymmet, har VirtuFit rätt utrustning för dig.`}</p>
    <h2 {...{
      "id": "produktserier-från-virtufit"
    }}>{`Produktserier från VirtuFit`}</h2>
    <h3 {...{
      "id": "power-bands"
    }}>{`Power Bands`}</h3>
    <p>{`VirtuFits Power Bands är en serie av träningsband som är utformade för att förbättra styrka, flexibilitet och uthållighet. Dessa band är tillverkade av högkvalitativt latex och finns i olika motståndsnivåer för att passa allas träningsbehov:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Power Band Medium 24 mm`}</strong>{`: Med ett motstånd på 15-30 kg, är detta band perfekt för både uppvärmning och styrketräning.`}</li>
      <li parentName="ul"><strong parentName="li">{`Power Band Light 22 mm`}</strong>{`: Erbjuder ett motstånd på 15-30 kg, idealiskt för nybörjare och lättare övningar.`}</li>
      <li parentName="ul"><strong parentName="li">{`Power Band Strong 45 mm`}</strong>{`: Kraftigt motstånd på 25-70 kg. Perfekt för avancerade styrkeövningar.`}</li>
      <li parentName="ul"><strong parentName="li">{`Power Band Extra Strong 64 mm`}</strong>{`: För de som söker tungt motstånd upp till 70 kg.`}</li>
    </ul>
    <h3 {...{
      "id": "resistance-cables"
    }}>{`Resistance Cables`}</h3>
    <p>{`VirtuFits Resistance Cables är gjorda i robust latex och erbjuder olika motståndsnivåer för en mångsidig träning:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Resistance Cable Medium`}</strong>{`: Motstånd på 5,44 kg, idealiskt för måttlig motståndsträning.`}</li>
      <li parentName="ul"><strong parentName="li">{`Resistance Cable Strong 6,8 kg`}</strong>{`: Perfekt för intensiv styrketräning med motstånd upp till 6,8 kg.`}</li>
      <li parentName="ul"><strong parentName="li">{`Figure 8 Resistance Cable`}</strong>{`: Formad som en åtta, vilket erbjuder unik anpassningsbarhet för olika övningar.`}</li>
    </ul>
    <h3 {...{
      "id": "resistance-kits-och-mini-bands"
    }}>{`Resistance Kits och Mini Bands`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`VirtuFit Resistance Kit`}</strong>{`: Inkluderar fem latexband med varierande motstånd från 4,5 kg till 13,5 kg. Perfekt för mångsidig träning både hemma och på resande fot.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mini Bands Comfort`}</strong>{`: Set med tre motståndsband i olika nivåer, tillverkade av en blandning av bomull och latex för högsta komfort.`}</li>
    </ul>
    <h3 {...{
      "id": "suspension-trainers-och-dip-belts"
    }}>{`Suspension Trainers och Dip Belts`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`VirtuFit Suspension Trainer Pro`}</strong>{`: Idealisk för helkroppsträning med suspension, tillverkad i slitstark nylon.`}</li>
      <li parentName="ul"><strong parentName="li">{`Dip Belt Pro Leather`}</strong>{`: Läderbälte för styrketräning med en maxbelastning på 100 kg.`}</li>
      <li parentName="ul"><strong parentName="li">{`Nylon Dip Belt`}</strong>{`: Tillverkat i slitstarkt nylon med en maxbelastning på 100 kg, perfekt för dips och chins.`}</li>
    </ul>
    <h3 {...{
      "id": "waist-trimming-belt"
    }}>{`Waist Trimming Belt`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`VirtuFit Waist Trimming Belt`}</strong>{`: Ett neopren- och läderbelt för optimal rygg- och midjestöd under träningen. Justerbar upp till 110 cm för en perfekt passform.`}</li>
    </ul>
    <h2 {...{
      "id": "köpguide-för-virtufit-träningsutrustning"
    }}>{`Köpguide för VirtuFit Träningsutrustning`}</h2>
    <p>{`När du ska välja rätt VirtuFit-produkt, överväg dina träningsmål och nuvarande nivå av fysisk aktivitet:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Styrka och Uthållighet`}</strong>{`: Power Bands är perfekta för dig som vill öka styrka och uthållighet. Välj motståndsnivå efter din erfarenhet - Medium för nybörjare och Extra Strong för avancerade användare.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Flexibilitet och Rehabilitering`}</strong>{`: Resistance Cables erbjuder olika motståndsnivåer för att passa rehabövningar och flexibilitetsträning.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Portabilitet och Variation`}</strong>{`: Resistance Kits och Mini Bands är utmärkta för dig som vill ha en mångsidig träningsutrustning som är lätt att ta med.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Helkroppsträning`}</strong>{`: Suspension Trainer Pro passar dig som vill ha ett komplett träningssystem för hela kroppen.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Extra Stöd`}</strong>{`: För de som behöver extra stöd vid tunga lyft eller annan intensiv träning, är Waist Trimming Belt och Dip Belts perfekta val.`}</p>
      </li>
    </ol>
    <p>{`Oavsett dina träningsbehov har VirtuFit produkterna som hjälper dig att nå dina mål. Utforska vårt sortiment och ta dina träningsrutiner till nästa nivå med VirtuFit träningsutrustning!`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      